<template>
  <div class="user_detail">
    <div class="q-pa-md">
        <q-list bordered separator style="background-color: white">
            <q-item>
                <q-item-section>{{ $t('login.user_id') }}</q-item-section>
                <q-item-section>{{ $t('login.username') }}</q-item-section>
                <q-item-section>{{ $t('login.remark') }}</q-item-section>
            </q-item>

            <q-item clickable v-ripple v-for="row in rows" :key="row.id" class="row justify-center" v-on:click="$router.push({name: 'User', params: {user_id: row.id}})">
                <q-item-section>{{ row.id }}</q-item-section>
                <q-item-section>{{ row.username }}</q-item-section>
                <q-item-section>{{ row.last_name }}</q-item-section>
            </q-item>

            <q-item class="row justify-end">
                <q-btn outline size="xm" color="secondary" icon="add" :label="$t('app.add_user')" v-on:click="$router.push({name: 'AddUser'})" />
            </q-item>
        </q-list>
    </div>
  </div>
</template>
<script>
export default {
    data () {
        return {
            rows: []
        }
    },
    methods: {
        async update_list() {
            let response = await this.$fetch('/api/v1/user');
            let data = await response.json();
            this.rows = data;
        }
    },
    async created () {
        this.update_list();
    },
}
</script>
<style scoped>
.cell {
    border-style: solid, hidden, hidden, hidden;
    border-width: 1px;
    border-color: grey;

    text-align: center;

    padding-top: 5px;
    padding-bottom: 5px;
}
</style>