<template>
  <div class="home">
    <div class="row justify-evenly">
        <q-card class="info-card col-3" >
            <q-card-section class="column items-center">
                <div>{{ stat.user }}</div>
                <div>{{ $t('app.user') }}</div>
            </q-card-section>
        </q-card >
        <q-card class="info-card col-3" >
            <q-card-section class="column items-center">
                <div>{{ stat.farm }}</div>
                <div>{{ $t('app.farm') }}</div>
            </q-card-section>
        </q-card >
        <q-card class="info-card col-3" >
            <q-card-section class="column items-center">
                <div>{{ stat.tank }}</div>
                <div>{{ $t('app.tank') }}</div>
            </q-card-section>
        </q-card >
    </div>

    <UserList style="margin-top: 20px" />

     <div class="row justify-center" style="padding: 15px">
        <q-btn style="margin-left: 5px" outline :label="$t('login.change_admin_password')" type="submit" color="negative" v-on:click="$router.push({name: 'ChangePassword', params: {user_id: user_id}})" />
    </div>
  </div>
</template>

<script>
import UserList from '@/components/UserList.vue'

export default {
    data () {
        return {
            user_id: window.sessionStorage.getItem('user_id'),
            stat: {}
        }
    },
    created () {
        this.retrieveStat();
    },
    components: {UserList},
    methods: {
        async retrieveStat () {
            let response = await this.$fetch('/api/v1/stat');
            let data = await response.json();
            this.stat = data;
        }
    }
}
</script>